import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="container">
      <h1>Coming Soon!</h1>
      <p>We're working hard to bring you something amazing. Our website is currently under development.</p>
      <p>If you need to get in touch with us, please contact:</p>
      <div className="contact-info">
        <p>Phone: <a href="tel:+256772525312">+256 772 525 312</a></p>
        <p>CEO Email: <a href="mailto:batekerezad@gmail.com">batekerezad@gmail.com</a></p>
        <p>Company Email: <a href="mailto:info@nzainotravelafrica.com">info@nzainotravelafrica.com</a></p>
      </div>
    </div>
  );
}

export default App;
